// extracted by mini-css-extract-plugin
export var button = "boston-module--button--b2f3d";
export var contactContainer = "boston-module--contact-container--a2ea7";
export var contactText = "boston-module--contact-text--2a8d9";
export var container = "boston-module--container--44c1b";
export var contentContainer = "boston-module--content-container--c306d";
export var divider = "boston-module--divider--ab0c3";
export var imageContainer = "boston-module--image-container--883e2";
export var mainImage = "boston-module--main-image--4d89f";
export var map = "boston-module--map--4fad7";
export var remoteContainer = "boston-module--remote-container--52f53";
export var remoteText = "boston-module--remote-text--53a53";
export var subTitle = "boston-module--sub-title--07c51";
export var title = "boston-module--title--ff6d8";