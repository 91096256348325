import React from "react"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/boston.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import TextWithSideIcon from "../components/slices/TextWithSideIcon.js"
import LazyLoad from "react-lazyload"

const Boston = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicBoston {
        data {
          address {
            text
          }
          banner_image {
            alt
            url
          }
          body {
            ... on PrismicBostonDataBodyTextWithSideIcon {
              id
              slice_label
              slice_type
              primary {
                icon {
                  alt
                  url
                }
                text {
                  richText
                }
                title1 {
                  text
                }
              }
            }
          }
          contact_text {
            richText
          }
          remote_support_description {
            richText
          }
          title {
            text
          }
        }
      }
    }
  `).prismicBoston.data

  console.log(data)

  const slices = data.body.map((slice, index) => {
    let res = undefined
    switch (slice.slice_type) {
      case "text_with_side_icon":
        res = (
          <div key={index}>
            <TextWithSideIcon slice={slice} />
          </div>
        )

        break

      default:
        return
    }
    return res
  })

  return (
    <Layout
      title="Boston IT Support & Tech Services - TechCollective"
      description="Our Boston office provides tech support and IT services for businesses and nonprofits in the greater Boston area. Schedule a free consultation with us today. "
    >
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img
            src={data.banner_image.url}
            alt={data.banner_image.alt}
            className={styles.mainImage}
          />
          <h1 className={styles.title}>Boston TechCollective</h1>
        </div>
        <div className={styles.contentContainer}>
          <h2 className={styles.subTitle}>IT Support for Greater Boston</h2>
          {slices}
          <Link to="/free-consultation">
            <button className={styles.button}>FREE CONSULTATION</button>
          </Link>

          <div className={styles.divider}></div>
          <h2 className={styles.subTitle}>Remote Support</h2>

          <div className={styles.remoteContainer}>
            <div className={styles.remoteText}>
              <RichText
                render={data.remote_support_description.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>

            <a href="http://techcollective.screenconnect.com">
              <button className={styles.button}>REMOTE SESSION</button>
            </a>
          </div>
          <div className={styles.divider}></div>
          <h2 className={styles.subTitle}>Contact Us</h2>
          <div className={styles.contactContainer}>
            <div className={styles.contactText}>
              <RichText
                render={data.contact_text.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>
            <LazyLoad>
              <iframe
                className={styles.map}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39096.60806517187!2d-71.12911566029474!3d42.360864697901754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDLCsDIyJzMwLjQiTiA3McKwMDYnMjAuMiJX!5e0!3m2!1sen!2sus!4v1593193394014!5m2!1sen!2sus"
                title={"Boston TechCollective Map"}
              ></iframe>
            </LazyLoad>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Boston
