import React from "react"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../GatsbyLink"
import * as styles from "../../stylesheets/slices/textWithSideIcon.module.scss"

const TextWithSideIcon = ({ slice }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <div className={styles.iconCircle}></div>
        <img
          src={slice.primary.icon.url}
          alt={slice.primary.icon.alt}
          className={styles.iconImage}
        />
      </div>
      <div className={styles.body}>
        <h3 className={styles.title}>{slice.primary.title1.text}</h3>
        <RichText
          render={slice.primary.text.richText}
          serializeHyperlink={GatsbyLink}
        />
      </div>
    </div>
  )
}

export default TextWithSideIcon
